import React from "react";
import Layout from "../components/layout/Layout";
import TermContent from "../components/TermContent";

const Terms = () => {
  return <>
      <Layout title={'Terms et conditions'}>
          <TermContent/>
      </Layout>
  </>
}
export default Terms;