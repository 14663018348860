import React from "react";

const TermContent = () => {
    return (
        <>
            <div className="content-space-t-3">
                <div className="bg-dark">
                    <div className="container position-relative content-space-1 content-space-lg-1">
                        <h1 className="text-white">Conditions Générale de vente</h1>
                        <p className="text-white">Dernière mise à jour le 11/07/2022.</p>
                    </div>
                </div>
                <div className="container content-space-1 content-space-lg-2">
                    <div className="row">
                        <div className="col-md-4 col-lg-3 mb-3 mb-md-0">
                            <div className="navbar-expand-md">
                                <div className="d-grid mb-3">
                                    <button type="button" className="navbar-toggler btn btn-white w-100"
                                            data-bs-toggle="collapse" data-bs-target="#navbarVerticalNavMenu"
                                            aria-label="Toggle navigation" aria-expanded="false"
                                            aria-controls="navbarVerticalNavMenu">
                                    <span className="d-flex justify-content-between align-items-center">
                                        <span className="text-dark mb-0">Menu</span>
                                        <span className="navbar-toggler-default">
                                            <i className="bi-list"/>
                                        </span>
                                        <span className="navbar-toggler-toggled">
                                            <i className="bi-x"/>
                                        </span>
                                    </span>
                                    </button>
                                </div>
                                <div id="navbarVerticalNavMenu" className="collapse navbar-collapse">
                                    <ul id="navbarSettings"
                                        className="js-sticky-block js-scrollspy nav nav-tabs nav-link-gray nav-vertical">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#Art1">Article 1 - Objet</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art2">Article 2 – Prix</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art3">Article 3 – Commandes et expéditions</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art4">Article 4 –Validation de votre commande d’expédition</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art5">Article 5 – Paiement</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art6">Article 6 – Rétractation et Annulation</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art7">Article 7- Disponibilité</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art8">Article 8 – Enlèvement et Livraison (pour les professionnels)</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art9">Article 9 – Garantie</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art10">Article 10 – Responsabilité</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art11">Article 11 – Droit applicable en cas de litiges</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art12">Article 12 – Propriété intellectuelle</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art13">Article 13 – Données personnelles</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#Art14">Article 14 –Archivage Preuve</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 col-lg-9">
                            <h4 id="Art1">Article 1 - Objet</h4>
                            <div className="mb-7">
                                <p>
                                    Les présentes conditions régissent les prestations de service rendues par la société
                                    AFRISENDS située à Hamdallaye ACI 2000 , Rue 511 porte 165 Bamako-Mali
                                </p>
                            </div>
                            <div className="mb-7">
                                <h4>1.1 PRÉAMBULE</h4>
                                <p>
                                    La Société invite ses Utilisateurs à lire attentivement les présentes Conditions
                                    Générales de Vente et d’Utilisation (ci-après les "CGV/CGU"). Les CGV/CGU
                                    s'appliquent à tous les Services fournis par la Société à ses Clients d'une même
                                    catégorie, quelles que soient les clauses pouvant être inscrites sur les documents
                                    du Client et notamment ses conditions générales d'achat.
                                </p>
                                <p>
                                    Les CGV/CGU sont systématiquement communiquées au Client qui en fait la demande.
                                    Le Client est tenu de prendre connaissance des CGV/CGU avant toute passation de
                                    Commande.<br/>
                                    En cas de modification ultérieure des CGV/CGU, le Client est soumis à la version en
                                    vigueur lors de sa Commande.<br/>
                                    Les données enregistrées dans le système informatique de la Société constituent la
                                    preuve des transactions conclues avec le Client
                                </p>
                            </div>
                            <div className="mb-7">
                                <h4>1.2 DÉFINITIONS</h4>
                                <p>
                                    <strong>Client</strong> désigne toute personne physique ou morale qui passe une
                                    Commande sur le présent Site Internet PAR Email , par téléphone , par WhatsApp ou
                                    par les réseaux sociaux;
                                    <br/>
                                    <strong>Commande</strong> désigne toute commande passée par l’Utilisateur inscrit
                                    sur le présent Site, en vue de bénéficier des Services de la Société;
                                    <br/>
                                    <strong>Conditions Générales de Vente et d'Utilisation ou CGV/CGU</strong> désignent
                                    les présentes conditions générales de vente et d'utilisation en ligne ;
                                    <br/>
                                    <strong>Consommateur</strong> désigne l'acheteur personne physique qui n'agit pas
                                    pour des besoins professionnels et/ ou hors de son activité professionnelle ;
                                    <br/>
                                    <strong>Professionnel</strong> désigne l'acheteur personne morale ou physique qui
                                    agit dans le cadre de son activité professionnelle ;
                                    <br/>
                                    <strong>Services</strong> désigne toutes les prestations de service proposées aux
                                    Utilisateurs par la Société à travers le présent Site;
                                    <br/>
                                    <strong>Site</strong> désigne le présent Site, c’est-à-dire <a
                                    href="www.afrisends.com">www.afrisends.com</a>;
                                    <br/>
                                    <strong>Société</strong> désigne la Société AFRISENDS, plus amplement désignée à
                                    l'article I des présentes et
                                    <br/>
                                    <strong>Utilisateur</strong> désigne toute personne qui fait utilisation du Site.
                                    <br/>
                                    <strong>Délais</strong>
                                    <br/>
                                    <strong>Tarifs</strong>
                                </p>
                            </div>
                            <div className="mb-7">
                                <h4>1.3 INSCRIPTION</h4>
                                <p>
                                    L’inscription au Site est ouverte à toutes les personnes morales ou physiques
                                    majeures et jouissant de leurs pleines personnalités et capacités juridiques.
                                    L’utilisation des Services proposés sur le Site est conditionnée à l’inscription de
                                    l’Utilisateur sur le Site. L’inscription est gratuite.
                                </p>
                                <p>
                                    Pour procéder à l’inscription, l’Utilisateur doit remplir tous les champs
                                    obligatoires, sans lesquels le service ne peut être délivré. A défaut l’inscription
                                    ne pourra être complétée.<br/>
                                    Les Utilisateurs garantissent et déclarent sur l'honneur que toutes les informations
                                    communiquées sur le Site, notamment lors de leur inscription, sont exactes et
                                    conformes à la réalité. Ils s’engagent à mettre à jour leurs informations
                                    personnelles à partir de la page dédiée à ces dernières et disponible dans leur
                                    compte.<br/>
                                    Tout Utilisateur enregistré dispose d’un identifiant et d’un mot de passe. Ces
                                    derniers sont strictement personnels et confidentiels et ne devront en aucun cas
                                    faire l’objet d’une communication à des tiers sous peine de suppression du compte de
                                    l’Utilisateur enregistré contrevenant.
                                </p>
                                <p>
                                    Chaque Utilisateur inscrit est personnellement responsable du maintien de la
                                    confidentialité de son identifiant et de son mot de passe. La Société ne sera en
                                    aucun cas tenue pour responsable de l’usurpation d’identité d’un Utilisateur. Si un
                                    Utilisateur suspecte une fraude à n’importe quel moment, il devra contacter la
                                    Société dans les plus brefs délais afin que cette dernière puisse prendre les
                                    mesures nécessaires et régulariser la situation.
                                </p>
                                <p>
                                    Chaque Utilisateur, qu’il soit une personne morale ou physique, ne peut être
                                    titulaire que d’un compte sur le Site.<br/>
                                    En cas de non-respect des CGV/CGU, notamment la création de plusieurs comptes pour
                                    une seule personne ou encore la fourniture de fausses informations, la Société se
                                    réserve le droit de procéder à la suppression temporaire ou définitive de tous les
                                    comptes crées par l’Utilisateur enregistré contrevenant
                                    La suppression du compte entraîne la perte définitive de tous les avantages et
                                    services acquis sur le Site. Cependant, toute Commande réalisée et facturée par le
                                    Site avant la suppression du compte sera exécutée dans les conditions normales.
                                </p>
                                <p>
                                    En cas de suppression d’un compte par la Société pour manquement aux devoirs et
                                    obligations énoncées dans les CGV/CGU, il est formellement interdit à l’Utilisateur
                                    contrevenant de se réinscrire sur le Site directement, par le biais d’une autre
                                    adresse électronique ou par personne interposée sans l’autorisation expresse de la
                                    Société.<br/>
                                    En cas de suppression d’un compte par la Société pour manquement aux devoirs et
                                    obligations énoncées dans les CGV/CGU, il est formellement interdit à l’Utilisateur
                                    contrevenant de se réinscrire sur le Site directement, par le biais d’une autre
                                    adresse électronique ou par personne interposée sans l’autorisation expresse de la
                                    Société.
                                </p>
                            </div>
                            <div className="mb-7">
                                <h4>1.4 PRESTATION DE SERVICE ET PRIX</h4>
                                <p>
                                    Les Services faisant l’objet des CGV/CGU sont ceux qui figurent sur le Site et qui
                                    sont proposés directement par la Société ou ses prestataires partenaires
                                    Les services sont décrits sur la page correspondante au sein du Site et mention est
                                    faite de toutes leurs caractéristiques essentielles. La Société ne peut être tenue
                                    responsable de l’impossibilité de réaliser ladite prestation lorsque l’Utilisateur
                                    n’est pas éligible.
                                </p>
                                <p>
                                    Lorsqu’un Utilisateur enregistré souhaite obtenir une prestation de service vendue
                                    par la Société à travers le Site, le prix indiqué sur la page de la prestation
                                    correspond au prix en euros hors taxes (HT) et tient compte des réductions
                                    applicables et en vigueur le jour de la Commande. Le prix indiqué n’inclut pas les
                                    frais accessoires qui seront indiqués le cas échéant dans le récapitulatif avant de
                                    passer commande.<br/>
                                    La Société se réserve la possibilité de modifier à tout moment ses tarifs. Elle
                                    s'engage à facturer les prestations au tarif applicable au moment de leur Commande.
                                    Pour les prestations dont le prix ne peut être connu a priori ni indiqué avec
                                    certitude, un devis détaillé sera transmis au Client
                                    En aucun cas un Utilisateur ne pourra exiger l’application de réductions n’étant
                                    plus en vigueur le jour de la Commande.
                                </p>
                            </div>

                            <h4 id="Art2">Article 2 - Prix</h4>
                            <div className="mb-7">
                                <p>
                                    Les prix de nos produits sont indiqués en euros ou en francs CFA toutes taxes
                                    comprises (TVA et autres taxes applicables au jour de la commande), sauf indication
                                    contraire et hors frais de traitement et d’expédition.
                                </p>
                                <p>
                                    En cas de commande vers un pays autre que la France métropolitaine vous êtes
                                    l’importateur du ou des produits concernés. Des droits de douane ou autres taxes
                                    locales ou droits d’importation ou taxes d’état sont susceptibles d’être exigibles.
                                </p>
                                <strong>
                                    Ces droits et sommes ne relèvent pas du ressort de la société AFRISENDS
                                </strong>
                                <p>
                                    Ils seront à votre charge et relèvent de votre entière responsabilité, tant en
                                    termes de déclarations que de paiements aux autorités et organismes compétents de
                                    votre pays. Nous vous conseillons de vous renseigner sur ces aspects auprès de vos
                                    autorités locales.
                                </p>
                                <p>Les commandes quelle que soit leur origine sont payables en euros ou en francs CFA
                                    selon l’accord conclus entre la société AFRISENDS et le client.
                                    La société AFRISENDS se réserve le droit de modifier ses prix à tout moment, mais le
                                    produit
                                    sera facturé sur la base du tarif en vigueur au moment de la validation de la
                                    commande et sous réserve de disponibilité.
                                    Les produits commandés par nos soins demeurent sous la garde de la société AFRISENDS
                                    jusqu’au paiement complet du prix par le client.
                                </p>
                                <strong>
                                    Attention : dès que vous prenez possession physiquement des produits commandés, les
                                    risques de perte ou d’endommagement des produits vous sont transférés vous disposez
                                    de 24heures afin de faire une réclamation à nos équipes.
                                </strong>
                            </div>

                            <h4 id="Art3">Article 3 - Commandes et expéditions</h4>
                            <div className="mb-7">
                                <p>
                                    Vous pouvez passer commande ou demander une expédition :
                                </p>
                                <ul>
                                    <li>Sur Internet : <a href="www.afrisends.com">www.afrisends.com</a></li>
                                    <li>Par téléphone au 07 67 62 26 62 du lundi au vendredi de 9H à 17H</li>
                                    <li>Sur notre compte Whatsapp Business +33 7 67 62 26 62</li>
                                </ul>
                                <p>
                                    Les informations contractuelles sont présentées en langue française et feront
                                    l’objet d’une
                                    confirmation au plus tard au moment de l’enlèvement ou la réception de votre colis.
                                </p>
                                <p>
                                    La société AFRISENDS se réserve le droit de ne pas enregistrer un paiement, et de ne
                                    pas confirmer une commande ou expédition pour quelque raison que ce soit, et plus
                                    particulièrement en cas de problème survenu sur les voies d’expédition aérienne ou
                                    maritime (grève, accident...), ou en cas de difficulté concernant la commande reçue
                                    (colis au mauvais format...).
                                </p>
                                <div style={{paddingBottom: 10,fontWeight:'bold'}}>Les commandes</div>
                                <p>
                                    Lors de la demande de passation de commande du client par AFRISENDS, une
                                    confirmation est envoyé au client avec un devis, le client est tenu de :
                                </p>
                                <ul>
                                    <li>vérifier les détails du devis (couleur, taille, délai de livraison)</li>
                                    <li>la validation du devis vaut comme confirmation des détails envoyés.</li>
                                    <li>la remise de la totalité du montant de la commande est requise pour passation
                                    </li>
                                </ul>
                                <p>

                                    Toutes confirmation de commande entraine acceptation des présentes conditions
                                    générales de vente, la reconnaissance d’en avoir parfaite connaissance.
                                    La commande est ensuite passée dans un délai de 48 à 72h, pendant ce délai aucune
                                    modification de commande n’est prise en compte. Une confirmation de commande est
                                    mise à disposition sur la plateforme Afrisends
                                </p>
                                <p>
                                    Le client est responsable du vendeur qu'il choisit, il doit donc s’assurer de sa
                                    fiabilité.
                                </p>
                                <p>
                                    AFRISENDS se charge uniquement de passer la commande et de vous communiquer les
                                    informations de livraison le cas échéant.
                                    La responsabilité d'AFRISENDS n'est pas engagé dans les conditions ci-dessous :
                                </p>
                                <ul>
                                    <li>La non-livraison</li>
                                    <li>La réception d'un produit non-conforme</li>
                                    <li>Le non-remboursement suite à une demande</li>
                                    <li>La cessation d'activité du vendeur</li>
                                    <li>Le délai de livraison du vendeur</li>
                                </ul>
                                <p>
                                    Nous ferons de notre possible pour vous aider à mettre à disposition le colis pour
                                    les retours éventuels cependant, toutes les informations ayant été transféré, le
                                    client a le devoir de procéder à la réclamation.
                                </p>
                                <strong>Les expéditions</strong>
                                <p>
                                    Après la prise de toutes les informations le détail de votre colis est demandé et
                                    fait foi de liste de colisage, aucun objet non spécifiés ne pourra être réclamé.
                                </p>
                                <p>
                                    A chaque expédition une assurance est proposée au client, si accepté le client sera
                                    remboursé selon les conditions de l’assurance. Dans le cas contraire, le client sera
                                    indemnisé selon la base des indemnisations prévues par AFRISENDS.
                                </p>
                                <p>
                                    Voici la liste des articles que nous ne transportons pas, si un de ces articles est
                                    contenu dans l’un de vos colis, il pourrait faire l’objet d’une destruction sans
                                    remboursement des frais d’expédition.
                                </p>
                                <ul style={{listStyleType:'none'}}>
                                    <li>1.  Matières biologiques périssables, infectieuses ou non infectieuses.</li>
                                    <li>2.  Organes et dépouilles humains.</li>
                                    <li>3.  Stupéfiants et matières psychotropes.</li>
                                    <li>4.  Produits liquides ; Boissons alcoolisés</li>
                                    <li>5.  Médicaments (sauf conditions spécifiques).</li>
                                    <li>6.  Produits de contrefaçon.</li>
                                    <li>7.  Animaux vivants (sauf conditions spécifiques).</li>
                                    <li>8.  Animaux morts, trophées de chasse, pièces d'origine animale telles que l'ivoire,
                                    les ailerons de requin et la chair animale, les sous-produits animaux et les
                                    produits dérivés non destinés à la consommation humaine, dont les mouvements sont
                                    contrôlés par la CITES Convention et/ou droit local.</li>
                                    <li>9.  Matières explosives (y compris les munitions inertes ou factices), inflammables
                                    (alcools, parfums) radioactives ou corrosives.</li>
                                    <li>10.  Matières dangereuses telles que le gaz comprimé ou tout objet contenant du gaz
                                    ou de l’air comprimé ou liquide (produits aérosols, canots de sauvetage,
                                    extincteurs, etc...).</li>
                                    <li>11.  Matières toxiques</li>
                                    <li>12.  Tout moyen de paiement cartes de crédit activées, pièces de monnaie, billets,
                                    timbres fiscaux (sauf spécifications spécifiques).</li>
                                    <li>13.  Bijoux, métaux précieux, billets de banque, valeurs au porteur, or ou argent, et
                                    autres objets de valeurs (sauf conditions spécifiques).</li>
                                    <li>14.  Objets qui par leur nature ou leur emballage, peuvent présenter un danger pour
                                    les agents, salir ou détériorer les affaires personnelles du voyageur ou des autres
                                    voyageurs ou l’équipement utilisé par le voyageur.</li>
                                    <li>15.  Déchets, plantes vivantes, bétail, animaux (sauf conditions spécifiques)</li>
                                    <li>16.  Biens culturels ayant un intérêt majeur pour le patrimoine national (sauf
                                    conditions spécifiques).</li>
                                    <li>17.  Envois dont le caractère sensible est soumis à des formalités particulières :
                                    armes, médicaments,tabacs, amiante, marchandises stratégiques utilisables à des fins
                                    civiles ou militaires ou produits issus d’espèces animales ou végétales sauvages
                                    protégées par la Convention de Washington.</li>
                                    <li>18.  Produits finis du tabac (cigarettes, cigares)</li>
                                    <li>19.  Cigarette électronique</li>
                                    <li>20.  Talismans</li>
                                </ul>
                                <div style={{paddingBottom: 10,fontWeight:'bold'}}>La réexpédition de commande</div>
                                <p>
                                    Le client doit envoyer sa confirmation de commande à l’adresse
                                    commande@afrisends.com afin de nous notifier d’une prochaine livraison à venir.
                                    A la suite de cela, il reçoit un mail de spécification d’expédition auquel il
                                    doit répondre.
                                </p>
                                <p>
                                    Le client est tenu de s’assurer que la livraison de sa commande aura lieu
                                    pendant nos horaires d’ouverture et que son contenu et sa dimension sont accepté
                                    par AFRISENDS.<br/>
                                    AFRISENDS ne saurait être tenu responsable d’une perte d’un colis livré en
                                    boites aux lettres ou d’un statut livré à nos horaires de fermeture.
                                    Le client est responsable de sa commande de la passation de la commande à la
                                    livraison à notre entrepot, cela comprend son suivi jusqu’à la livraison à notre
                                    entrepot, AFRISENDS n’interviendra pas dans cet intervalle.<br/>
                                    Une fois le colis réceptionné en entrepôt le client recoit une confirmation de
                                    réception par mail dans un délai de 48h suivant la livraison du colis, sauf avis
                                    contraire de sa part lors de l’envoi de sa confirmation de commande ou 24h après
                                    avoir reçu le mail de confirmation, la commande est expédiée et le montant de
                                    l’expédition est dûe.
                                </p>
                                <p>
                                    Si le client souhaite une expédition groupée il est tenu de nous en informé dès
                                    la passation de la première commande. Les colis seront consignés durant 30 jours
                                    en entrepôt, passé ce délai les articles reçus feront l’objet d’une expédition.
                                </p>
                            </div>

                            <h4 id="Art4">Article 4 - Validation de votre commande d’expédition</h4>
                            <div className="mb-7">
                                <p>
                                    Toute commande du service AFRISENDS suppose l’adhésion aux présentes Conditions
                                    Générales. Toutes confirmations de commande et d’expédition entraîne votre adhésion
                                    pleine et entière aux présentes conditions générales de vente, sans exception ni
                                    réserve.
                                    L’ensemble des données fournies et la confirmation enregistrée vaudront preuve de la
                                    transaction. Vous déclarez en avoir parfaite connaissance.
                                    La confirmation de commande vaudra signature et acceptation des opérations
                                    effectuées.
                                </p>
                            </div>

                            <h4 id="Art5">Article 5 - Paiement</h4>
                            <div className="mb-7">
                                <p>
                                    Le fait de valider votre commande implique pour vous l’obligation de payer le prix
                                    indiqué.
                                    Le règlement de vos achats s’effectue par carte bancaire, par mobile money, par
                                    virement, par espèces à la livraison ou à l’enlèvement.
                                    Le débit de la carte est effectué dès la communication de ces derniers et votre
                                    acceptation à régler la commande même en cas de livraison fractionné.
                                    AFRISENDS se réserve le droit de non-exécution si la totalité ou une partie de la
                                    somme n’est pas réglé.
                                </p>
                            </div>

                            <h4 id="Art6">Article 6 - Rétractation et Annulation</h4>
                            <div className="mb-7">
                                <p>
                                    Conformément aux dispositions de l’article L.121-21 du Code de la Consommation, vous
                                    disposez d’un délai de rétractation de 14 jours à compter de la réception de vos
                                    produits pour exercer votre droit de rétraction sans avoir à justifier de motifs ni
                                    à payer de pénalité.
                                </p>
                                <p>
                                    Les retours de commandes sont à effectuer dans les délais impartis par le commerçant
                                    en prenant en compte le délai de transport retour dans leur état d’origine et
                                    complets (emballage, accessoires, notice). Dans ce cadre, votre responsabilité est
                                    engagée. Tout dommage subi par le produit à cette occasion peut être de nature à
                                    faire échec au droit de rétractation. Les frais de retour sont à votre charge.
                                </p>
                                <p>
                                    En cas d’exercice du droit de rétractation, avant expédition la société AFRISENDS
                                    procédera au remboursement des sommes versées, dans un délai de 14 jours suivant la
                                    notification de votre demande et via le même moyen de paiement que celui utilisé
                                    lors de la commande.
                                </p>
                                <br/>
                                <div style={{paddingBottom: 10,fontWeight:'bold'}}>EXCEPTIONS AU DROIT DE RETRACTATION</div>
                                <p>Conformément aux dispositions de l’article L.121-21-8 du Code de la Consommation, le
                                    droit de rétractation ne s’applique pas à :</p>
                                <ul>
                                    <li>La fourniture de services pleinement exécutés avant la fin du délai de
                                        rétractation et dont l’exécution a commencé après accord préalable exprès du
                                        consommateur et renoncement exprès à son droit de rétractation.
                                    </li>
                                    <li>La fourniture de biens ou de services dont le prix dépend de fluctuations sur le
                                        marché financier échappant au contrôle du professionnel et susceptibles de se
                                        produire pendant le délai de rétractation.
                                    </li>
                                    <li>La fourniture de biens confectionnés selon les spécifications du consommateur ou
                                        nettement personnalisés.
                                    </li>
                                    <li>La fourniture de biens susceptibles de se détériorer ou de se périmer
                                        rapidement.
                                    </li>
                                    <li>La fourniture de biens qui ont été descellés par le consommateur après la
                                        livraison et qui ne peuvent être renvoyés pour des raisons d’hygiène ou de
                                        protection de la santé.
                                    </li>
                                    <li>La fourniture de biens qui, après avoir été livrés et de par leur nature, sont
                                        mélangés de manière indissociable avec d’autres articles ;
                                    </li>
                                    <li>La fourniture de boissons alcoolisées dont la livraison est différée au-delà de
                                        trente jours et dont la valeur convenue à la conclusion du contrat dépend de
                                        fluctuations sur le marché échappant au contrôle du professionnel.
                                    </li>
                                    <li>La fourniture d’enregistrements audio ou vidéo ou de logiciels informatiques
                                        lorsqu’ils ont été descellés par le consommateur après la livraison.
                                    </li>
                                    <li>La fourniture d’un journal, d’un périodique ou d’un magazine, sauf pour les
                                        contrats d’abonnement à ces publications.
                                    </li>
                                    <li>De prestations de services d’hébergement, autres que d’hébergement résidentiel,
                                        de services de transport de biens, de locations de voitures, de restauration ou
                                        d’activités de loisirs qui doivent être fournis à une date ou à une période
                                        déterminée.
                                    </li>
                                    <li>Les transactions conclues lors d’une enchère publique.</li>
                                    <li>La fourniture d’un contenu numérique non fourni sur un support matériel dont
                                        l’exécution a commencé après accord préalable exprès du consommateur et
                                        renoncement exprès à son droit de rétractation.
                                    </li>
                                </ul>
                                <br/>
                                <div style={{paddingBottom: 10,fontWeight:'bold'}}>ANNULATION D’EXPEDITION</div>
                                <p>
                                    Si le client souhaite annuler son expédition AFRISENDS, il est tenu de nous en
                                    informer avant l’expédition de son colis, si le colis a déjà changé de statut il ne
                                    sera malheureusement pas possible de l’annuler. Le client est chargé de planifier
                                    l’enlèvement et la récupération de son colis à notre entrepôt pour une livraison au
                                    lieu de son choix.
                                </p>
                            </div>

                            <h4 id="Art7">Article 7 - Disponibilité</h4>
                            <div className="mb-7">
                                <p>
                                    Nos offres sont valables sous réserve de disponibilité d’expédition aérienne et
                                    maritime.<br/>
                                    En cas d’indisponibilité d’expédition après passation de votre commande, nous vous
                                    en informerons par mail. Votre commande AFRISENDS sera automatiquement annulée et
                                    remboursé ou conservé comme avoir.
                                </p>
                            </div>

                            <h4 id="Art8">Article 8 - Enlèvement et Livraison (pour les professionnels)</h4>
                            <div className="mb-7">
                                <p>
                                    Les colis sont retirés à l’adresse de l’expéditeur indiqué au cours de la
                                    réservation.
                                </p>
                                <p>
                                    Les produits sont livrés à l’adresse de livraison indiquée au cours du processus de
                                    commande, dans le délai indiqué.
                                    Le client En cas de retard d’expédition, un mail vous sera adressé pour vous
                                    informer d’une éventuelle conséquence sur le délai de livraison qui vous a été
                                    indiqué.
                                </p>
                                <p>
                                    Dans le cas ou le client demande une livraison de ces biens achetés sur un site
                                    e-commerce AFRISENDS ne peut être tenu responsable des délais de livraison, de la
                                    casse ou de la perte du colis par le site e-commerce.<br/>
                                    Conformément aux dispositions légales, en cas de retard de livraison, vous
                                    bénéficiez de la possibilité d’annuler la commande AFRISENDS dans les conditions et
                                    modalités définies à l’article L 138-2 du Code de la Consommation. En cas de
                                    livraisons par un transporteur, la société AFRISENDS ne peut être tenue pour
                                    responsable de retard de livraison dû exclusivement à une indisponibilité du client
                                    après plusieurs propositions de rendez-vous.
                                </p>
                            </div>

                            <h4 id="Art9">Article 9 - Garantie</h4>
                            <div className="mb-7">
                                <p>
                                    Toutes les réclamations, demandes d’échange ou de remboursement de vos commandes
                                    doivent s’effectuer avec le vendeur. Les frais d’expédition AFRISENDS ne seront pas
                                    remboursés. Les dispositions de cet Article ne vous empêchent pas de bénéficier du
                                    droit de rétractation prévu à l’article 6.
                                </p>
                            </div>

                            <h4 id="Art10">Article 10 - Responsabilité</h4>
                            <div className="mb-7">
                                <p>
                                    Les produits et services proposés sont conformes à la législation française en
                                    vigueur.
                                </p>
                                <p>
                                    La responsabilité de la société AFRISENDS ne saurait être engagée en cas de
                                    non-respect de la
                                    Législation du pays où le produit est livré. Il vous appartient de vérifier auprès
                                    des autorités locales
                                    les possibilités d’importation ou d’utilisation des produits ou services que vous
                                    envisagez de
                                    commander.
                                </p>
                                <p>
                                    Pour les passations de commande, le client se doit de vérifier la fiabilité du site
                                    internet sur lequel il souhaite commander, en cas de litige, de non réception, de
                                    réception de mauvais article AFRISENDS ne saurait etre tenu responsable.
                                </p>
                                <p>
                                    Par ailleurs, la société AFRISENDS ne saurait être tenue pour responsable des
                                    dommages résultant d’une mauvaise utilisation du produit acheté.
                                    Enfin la responsabilité de la société AFRISENDS ne saurait être engagée pour tous
                                    les inconvénients ou dommages inhérents à l’utilisation du réseau Internet,
                                    notamment une rupture de service, une intrusion extérieure ou la présence de virus
                                    informatiques.
                                </p>

                            </div>

                            <h4 id="Art11">Article 11 - Droit applicable en cas de litiges</h4>
                            <div className="mb-7">
                                <p>
                                    La langue du présent contrat est la langue française. Les présentes conditions de
                                    vente sont soumises à la loi française. En cas de litige, les tribunaux français
                                    seront les seuls compétents.
                                </p>
                            </div>

                            <h4 id="Art12">Article 12 - Propriété intellectuelle</h4>
                            <div className="mb-7">
                                <p>
                                    Tous les éléments du site www.AFRISENDS.com sont et restent la propriété
                                    intellectuelle et exclusive de la société AFRISENDS. Nul n’est autorisé à
                                    reproduire, exploiter, rediffuser, ou utiliser à quelque titre que ce soit, même
                                    partiellement, des éléments du site qu’ils soient logiciels, visuels ou sonores.
                                    Tout lien simple ou par hypertexte est strictement interdit sans un accord écrit
                                    exprès de la société AFRISENDS.
                                </p>
                            </div>

                            <h4 id="Art13">Article 13 – Données personnelles</h4>
                            <div className="mb-7">
                                <p>
                                    La société AFRISENDS se réserve le droit de collecter les informations nominatives
                                    et lesdonnées personnelles vous concernant. Elles sont nécessaires à la gestion de
                                    votre commande, ainsi qu’à l’amélioration des services et des informations que nous
                                    vous adressons.
                                    Elles peuvent aussi être transmises aux sociétés qui contribuent à ces relations,
                                    telles que celles chargées de l’exécution des services et commandes pour leur
                                    gestion, exécution, traitement et paiement.
                                </p>
                                <p>
                                    Ces informations et données sont également conservées à des fins de sécurité, afin
                                    de respecter les obligations légales et réglementaires.
                                    Conformément à la loi du 6 janvier 1978, vous disposez d’un droit d’accès, de
                                    rectification et d’opposition aux informations nominatives et aux données
                                    personnelles vous concernant, directement sur le site Internet.
                                </p>
                            </div>

                            <h4 id="Art14">Article 14 –Archivage Preuve</h4>
                            <div className="mb-7">
                                <p>
                                    La société AFRISENDS archivera les bons de commandes et les factures sur un support
                                    fiable et durable constituant une copie fidèle conformément aux dispositions de
                                    l’article 1348 du Code civil.
                                    Les registres informatisés de la société AFRISENDS seront considérés par toutes les
                                    parties concernées comme preuve des communications, commandes, paiements et
                                    transactions intervenus entre les parties.
                                </p>
                            </div>

                            <div id="stickyBlockEndPoint"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermContent;
